<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Mi Calendario</h4>
            <div class="small text-muted">Planificación semanal</div>
          </b-col> 
          <b-col sm="5">
            <div>
              <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
                <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                  <i class="fa fa-filter"></i> Mostrar Filtros
                </b-button>
              </b-col>
              <b-col sm="12" class="d-none d-md-block" v-else>
                <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                  <i class="fa fa-window-close"></i>
                </b-button>
              </b-col>
            </div>
          </b-col>                             
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">

        <div>
          <b-row v-if="table.mostrarFiltros" class="mb-3">               
            <b-col sm="3">
              <b-form-datepicker v-model="filters.date_end" placeholder="Fecha de Finalización" local="es"></b-form-datepicker>                      
            </b-col>
            <b-col sm="2">
              <b-button variant="outline-dark" @click="filterCalendar()">
                <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                Filtrar
              </b-button>
            </b-col>          
          </b-row>
        </div>

        <b-row>
          <b-col>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"
                    :filter="table.filter"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    selectable
                    select-mode="single"
                    @row-selected="onRowSelected"                      
                    :busy="table.isBusy"
                    v-if="table.items.length || table.isBusy">                       
                
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Cargando...</strong>
                </div>
              </template>

              <template v-slot:row-details="row">
                <b-card>
                  <b-row>
                    <b-col md="10">
                      <b-row>
                        <b-col md="6">
                          <div class="sellers-list-calendar-titulo-subline mb-2">
                            <div v-if="row.item.activity">
                              <b>Asignación:</b> {{row.item.activity}}
                            </div>
                            <div v-else>
                              <b>Asignación:</b> {{row.item.zone.name}}
                            </div>
                          </div>
                        </b-col>                          
                      </b-row>
                      <b-row>
                        <b-col md="12">                          
                          <b-table class="mb-0"
                                  responsive="sm"
                                  head-variant="dark"
                                  :hover="true"
                                  :small="true"
                                  :fixed="false"
                                  :items="tableSub.items"
                                  :fields="tableSub.fields"                            
                                  v-if="tableSub.items.length">   
                            
                            <template v-slot:cell(code)="row">
                              <b>{{row.item.customer.code}}</b>                              
                            </template> 
                                                             
                            <template v-slot:cell(customers_id)="row">
                              <b-avatar :src="row.item.customer.image"
                                        v-if="row.item.customer.image">
                              </b-avatar>
                              <b-avatar v-else    
                                        icon="building"              
                                        variant="dark">
                              </b-avatar>     
                              &nbsp;<b>{{row.item.customer.name}}</b>
                              <b-icon icon="circle-fill" v-if="!row.item.customer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />                
                            </template>   

                            <template v-slot:cell(last_news)="row">
                              <div v-html="setDateToReports(row.item, 'news')" />
                            </template>                               
                            <template v-slot:cell(last_payment)="row">
                              <div v-html="setDateToReports(row.item, 'payments')" />
                            </template>                               
                            <template v-slot:cell(last_visits)="row">
                              <div v-html="setDateToReports(row.item, 'visits')" />
                            </template>  
                            <template v-slot:cell(last_services)="row">
                              <div v-html="setDateToReports(row.item, 'services')" />
                            </template>
                            <template v-slot:cell(last_marketing)="row">
                              <div v-html="setDateToReports(row.item, 'marketing')" />
                            </template>
                            <template v-slot:cell(last_audit)="row">
                              <div v-html="setDateToReports(row.item, 'audit')" />
                            </template>                                                         

                            <template v-slot:cell(f_action)="row">
                              <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">                                
                                <b-dropdown-item @click="goDetailCustomer(row.item)">
                                  <i class="fa fa-eye"></i> Detalle Cliente
                                </b-dropdown-item>
                                <b-dropdown-header>Reportes</b-dropdown-header>
                                <b-dropdown-item @click="goReportVisit(row.item)" v-if="accessExtras.INFORME_VISITAS.status">
                                  <i class="fa fa-map-marker"></i> Reporte de Visitas
                                </b-dropdown-item>
                                <b-dropdown-item @click="goReportPayment(row.item)" v-if="accessExtras.INFORME_COBRANZAS.status">
                                  <i class="fa fa-dollar"></i> Reporte de Cobranzas
                                </b-dropdown-item>
                                <b-dropdown-item @click="goReportNews(row.item)" v-if="accessExtras.INFORME_NOVEDADES.status">
                                  <i class="fa fa-comment"></i> Reporte de Novedades
                                </b-dropdown-item>                                                                
                              </b-dropdown>
                            </template>                           
                          </b-table>
                          <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>                          
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card>
              </template>

              <template v-slot:cell(period)="data">
                <b>{{moment(data.item.date_start).format("DD MMMM")}}</b> 
                <span> al </span> 
                <b>{{moment(data.item.date_end).format("DD MMMM")}}</b>
              </template>

              <template v-slot:cell(assigned)="data">
                <div v-if="data.item.activity">
                  <b>ACTIVIDAD:</b> {{data.item.activity}}
                </div>
                <div v-else>
                  <b>ZONA:</b> {{data.item.zone.name}}
                </div>                
              </template>

              <template v-slot:cell(observations)="data">
                <div v-if="data.item.observation">                      
                  <div v-if="data.item.observation.length>30" :title="data.item.observation">
                    {{data.item.observation.substring(0,30) + "..."}}
                  </div>
                  <div v-else>
                    {{data.item.observation}}
                  </div>                                                                    
                </div>
              </template>

              <template v-slot:cell(status)="data">
                <div v-html="statusCalendar(data.index)"></div>
              </template>  

            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
        
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav>
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="getRowCount(table.items)"
                            :per-page="table.perPage"
                            v-model="table.currentPage" />
            </nav>
          </b-col>          
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              size="sm"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>
          <b-col>
            {{this.modal.form.detail}}
          </b-col>
        </b-row>        

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false" size="sm">Cancelar</b-button>          
        </div>
      </b-modal>


    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'   
  import Funciones from '@/handler/funciones'   
  import moment from 'moment'

  export default {
    data: () => {
      return {      
        access: {
          module_id: Modules.VENDEDORES,
          profile_id: Profiles.PERSONAL,
          view_reference: 'listCalendarSellers',
          elements: {}
        }, 
        accessExtras:{
          INFORME_VISITAS: {
            module: Modules.INFORME_VISITAS,
            status: false
          }, 
          INFORME_COBRANZAS: {
            module: Modules.INFORME_COBRANZAS,
            status: false
          }, 
          INFORME_NOVEDADES: {
            module: Modules.INFORME_NOVEDADES,
            status: false
          },
          INFORME_SERVICIOS: {
            module: Modules.INFORME_SERVICIOS,
            status: false
          },
          INFORME_MARKETING: {
            module: Modules.INFORME_MARKETING,
            status: false
          },
          INFORME_AUDITORIA: {
            module: Modules.INFORME_AUDITORIA,
            status: false
          }          
        }, 
        table : {
          items: [],
          fields: [            
            {key: 'period', label: 'Período'},            
            {key: 'assigned', label: 'Asignado'},            
            {key: 'observations', label: 'Observaciones'},            
            {key: 'status', label: 'Estado', class: 'text-center'},            
          ],
          filter: null,
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 50,
          isBusy: false,
        },
        tableSub : {
          items: [],
          fields: [],
          filter: null,
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 50,
        },
        modal: {
          form: {
            active: false,
            title: '',
            detail: ''
          },        
        },
        arr: {
          calendars: [], 
          customers: [],
        },     
        filters: {         
          date_end: moment().add(7, 'd').format('YYYY-MM-DD')
        }                       
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos extras para vincular modulos */
      this.accessExtras.INFORME_VISITAS.status = Helper.moduleEnabled(this.accessExtras.INFORME_VISITAS.module)
      this.accessExtras.INFORME_COBRANZAS.status = Helper.moduleEnabled(this.accessExtras.INFORME_COBRANZAS.module)
      this.accessExtras.INFORME_NOVEDADES.status = Helper.moduleEnabled(this.accessExtras.INFORME_NOVEDADES.module)
      this.accessExtras.INFORME_SERVICIOS.status = Helper.moduleEnabled(this.accessExtras.INFORME_SERVICIOS.module)
      this.accessExtras.INFORME_MARKETING.status = Helper.moduleEnabled(this.accessExtras.INFORME_MARKETING.module)
      this.accessExtras.INFORME_AUDITORIA.status = Helper.moduleEnabled(this.accessExtras.INFORME_AUDITORIA.module)      
      /* Fin configuracion */
    },  
    mounted() {
      this.filterCalendar()      
    },
    methods: {
      getRowCount (items) {
        return items.length
      },
      onRowSelected(item) {      
        this.arr.calendars.forEach((element, index) => {
          if(item.length){
            if(element.id == item[0].id) {              
              if( element.zone_id ) {
                this.loadCustomersZone(element.zone_id)
                this.openDetail(index)                 
              } else {
                this.modal.form.active = true
                this.modal.form.title = 'Informacíon Calendario'
                this.modal.form.detail = 'Este período esta asignado a una actividad.'
              }
            }
          }
        }) 
      },     
      openDetail(index) {                                
        this.table.rowSelected = index
        this.filterCalendar()
      },       
      load() {
        this.table.isBusy = true
        var result = serviceAPI.mostrarVendedorCalendario(Helper.getSeller().id)

        result.then((response) => {
          var data = response.data
          this.table.items = data
          this.arr.calendars = data

          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true       
            
            if(this.table.items[this.table.rowSelected].zone) {
              this.tableSub.items = this.table.items[this.table.rowSelected].zone.customers
            } else {
              this.tableSub.items = []
            }
          }  
          this.table.isBusy = false
        })
        .catch(error => {          
          this.table.isBusy = false
          this.$awn.alert(Error.showError(error))
        });        
      },    
      statusCalendar (index) {        
        var item = this.arr.calendars[index]
                      
        if (item.date_start <= moment().format('YYYY-MM-DD') && item.date_end >= moment().format('YYYY-MM-DD')) {          
          return "<span class='sellers-list-calendar-text-green'>Activo</span>";
        } else {
          if (item.date_end < moment().format('YYYY-MM-DD')) {            
            return "<span class='sellers-list-calendar-text-red'>Finalizado</span>";            
          } else {            
            return "<span class='sellers-list-calendar-text-orange'>Próximos</span>";                        
          }            
        }        
      }, 

      loadCustomersZone(zone_id) {
        var result = serviceAPI.mostrarClienteZona(zone_id)

        result.then((response) => {
          var data = response.data
          this.arr.customers = data              
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error))
        });            
      },
      setDateToReports(item, type) {        
        var result = "<span class='badge badge-danger'>Sin Cargar</span>"
        if(this.arr.customers) {          
          this.arr.customers.forEach(element => {            
            if(element.customers_id == item.customers_id) {
              if(element.customer){                        
                if(type=="news") {
                  if(element.customer["last-reports-news"]) {
                    result = "<span class='sellers-list-calendar-text-dark'>" + moment.duration(moment().diff(moment(element.customer["last-reports-news"].date),'days'),'days').humanize() + "</span>"
                  }
                }                

                if(type=="visits") {
                  if(element.customer["last-reports-visits"]) {
                    result = "<span class='sellers-list-calendar-text-dark'>" + moment.duration(moment().diff(moment(element.customer["last-reports-visits"].date),'days'),'days').humanize() + "</span>"                 
                  }
                } 

                if(type=="payments") {
                  if(element.customer["last-reports-payments"]) {
                    result = "<span class='sellers-list-calendar-text-dark'>" + moment.duration(moment().diff(moment(element.customer["last-reports-payments"].date),'days'),'days').humanize() + "</span>"
                  }                  
                } 

                if(type=="services") {
                  if(element.customer["last-reports-services"]) {
                    result = "<span class='sellers-list-calendar-text-dark'>" + moment.duration(moment().diff(moment(element.customer["last-reports-services"].date),'days'),'days').humanize() + "</span>"
                  }                  
                }   
                
                if(type=="marketing") {
                  if(element.customer["last-reports-marketing"]) {
                    result = "<span class='sellers-list-calendar-text-dark'>" + moment.duration(moment().diff(moment(element.customer["last-reports-marketing"].date),'days'),'days').humanize() + "</span>"
                  }                  
                }   
                
                if(type=="audit") {
                  if(element.customer["last-reports-audit"]) {
                    result = "<span class='sellers-list-calendar-text-dark'>" + moment.duration(moment().diff(moment(element.customer["last-reports-audit"].date),'days'),'days').humanize() + "</span>"
                  }                  
                }                  
              }              
            }
          });
        }
        return result
      },

      filterCalendar() {
        this.table.isBusy = true
        var result = serviceAPI.filtrarVendedorCalendario({
          sellers_id: Helper.getSeller().id,
          date_end: this.filters.date_end
        })

        result.then((response) => {
          var data = response.data
          this.table.items = data
          this.arr.calendars = data

          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true       
                          
            this.tableSub.fields.push({key: 'code', label: 'Código', class: 'text-center align-middle'})
            this.tableSub.fields.push({key: 'customers_id', label: 'Cliente', class: 'align-middle'})
            if(this.accessExtras.INFORME_VISITAS.status) {
              this.tableSub.fields.push({key: 'last_visits', label: 'Última Visita', class: 'text-center align-middle'})
            }
            if(this.accessExtras.INFORME_COBRANZAS.status) {
              this.tableSub.fields.push({key: 'last_payment', label: 'Última Cobranza', class: 'text-center align-middle'})
            }
            if(this.accessExtras.INFORME_NOVEDADES.status) {
              this.tableSub.fields.push({key: 'last_news', label: 'Última Novedad', class: 'text-center align-middle'})
            }
            if(this.accessExtras.INFORME_SERVICIOS.status) {
              this.tableSub.fields.push({key: 'last_services', label: 'Último Servicio', class: 'text-center align-middle'})
            }
            if(this.accessExtras.INFORME_MARKETING.status) {
              this.tableSub.fields.push({key: 'last_marketing', label: 'Última Marketing', class: 'text-center align-middle'})
            }
            if(this.accessExtras.INFORME_AUDITORIA.status) {
              this.tableSub.fields.push({key: 'last_audit', label: 'Última Auditoria', class: 'text-center align-middle'})
            }               
            this.tableSub.fields.push({key: 'f_action', label:'', class: 'align-middle'}) 

            if(this.table.items[this.table.rowSelected].zone) {
              this.tableSub.items = this.table.items[this.table.rowSelected].zone.customers
            } else {
              this.tableSub.items = []
            }
          }  

          this.table.isBusy = false
        })
        .catch(error => {        
          this.table.isBusy = false
          this.$awn.alert(Error.showError(error))
        });        
      }, 

      goReportVisit(item) {
        this.$router.push({ name: 'ReportsVisitsStaffCrudReportSellerByCustomers', params: {customersID: item.customers_id} })
      },
      goReportPayment(item) {
        this.$router.push({ name: 'ReportsPaymentStaffCrudReportSellerByCustomers', params: {customersID: item.customers_id + '|' + item.customer.code} })
      },
      goReportNews(item) {
        this.$router.push({ name: 'ReportsNewsStaffCrudReportSellerByCustomers', params: {customersID: item.customers_id} })
      },
      goDetailCustomer(item) {
        this.$router.push({ name: 'CustomerViewStaff', params: {customersID: item.customers_id} })        
      }      

    }    
  }
</script>
<style>
  .sellers-list-calendar-titulo-subline {
    font-size: 17px;
  }
  .sellers-list-calendar-text-orange {
    color: darkorange;
    font-weight: 900;
  }
  .sellers-list-calendar-text-green {
    color: darkgreen;
    font-weight: 900;
  }
  .sellers-list-calendar-text-red {
    color: darkred;
    font-weight: 900;
  }
  .sellers-list-calendar-text-dark {
    color: black;
    font-weight: 900;
  }  
</style>
<style scoped>
  .b-avatar {
    width: 25px;
    height: 25px;    
  }  
</style>